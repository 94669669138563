// import { Carousel } from "react-bootstrap";
// import Link from "next/link";
//import OptionDropdown from "components/category/about/optionDropdown/optionDropdown";
import { useDispatch, useSelector } from "react-redux";
// import { postLike } from "redux/actions/postAction";
import React, { useEffect, useState } from "react";
// import { useAmp } from "next/amp";
import CustomPopup from "components/shared/modal/customPopup";
// import { useSession } from "next-auth/react";
// import Image from "next/image";
import { getLanguage } from "localization/i8intl";
import { pushGoogleTabEvent, createHomeArticlePayload } from "api/googletab";
import { useRouter } from "next/router";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
  EmailIcon,
  EmailShareButton,
} from "next-share";
// import uiHelper from "utils/uiHelper";
// import { saveBookmark } from "redux/actions/bookmarksActions";
import { Modal } from "react-bootstrap";
// import { timeSince } from "constants/cardsConstant";
// import ClevertapReact from "utils/clevertapHelper";
import { MyLink } from "api/cleverTap";
// import Image from "next/image";

const NewsImageTextBanner1 = ({ postData, hTag }) => {
  console.log("NewsImageTextBanner1", postData);
  //const sessionData = JSON.parse(localStorage.getItem("lallantopSession2"));
  const router = useRouter();
  const { query } = router;
  // const { data: session } = useSession();
  // const { userLocalDetail } = useSelector(({ AuthReducer }) => AuthReducer);
  const [loginPrompt, setLoginPrompt] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [postStateData, setPostStateData] = useState(postData);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareData, setShareData] = useState({});
  const { postDetails } = useSelector(({ PostReducer }) => PostReducer);
  const isHomePath = router.asPath === "/";
  const dispatch = useDispatch();

  const [firstScroll, setFirstScroll] = useState(false);

  useEffect(() => {
    if (firstScroll === false) {
      function handleScroll() {
        setFirstScroll(true);
        window.removeEventListener("scroll", handleScroll);
      }

      window.addEventListener("scroll", handleScroll);
    }
  }, [firstScroll]);
  useEffect(() => {
    let timer = setTimeout(() => {
      setFirstScroll(true);
    }, 10000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  // const countFormat = (count) => {
  //   return count > 1000 ? (count / 1000).toFixed(1).concat("k") : count;
  // };
  // const handleLike = (_id, postDetails) => {
  //   if (userLocalDetail?.id || session?.user?.email) {
  //     const { seriesId } = postStateData;
  //     dispatch(postLike({ _id, seriesId }, null, postDetails));
  //   } else {
  //     setLoginPrompt(true);
  //   }
  // };
  const [language, setLanguage] = useState("");
  useEffect(() => {
    setLanguage(getLanguage());
  }, []);

  const pageToOpen = `/${
    postData?.categorySlug?.toLowerCase() || query?.categoryName
  }/post/${postData?.postSlug || ""}`;

  // const shareUrl = `${pageToOpen}?postId=${postStateData?._id}`;
  useEffect(() => {
    if (postDetails?._id === postData?._id) {
      setPostStateData(postDetails);
    }
  }, [postDetails, postData?._id]);
  // useEffect(() => {
  //   var au = document?.createElement("audio");
  //   au.src = postStateData?.assetFileLink;
  //   function handler() {
  //     var duration = au?.duration;
  //     setVideoDuration(new Date(duration * 1000).toISOString().substr(14, 5));
  //   }
  //   au.addEventListener("loadedmetadata", handler);
  //   return () => {
  //     au.removeEventListener("loadedmetadata", handler);
  //   };
  // }, [postStateData]);

  // const handleShare = () => {
  //   const share = {
  //     title: postData?.title,
  //     text: postData?.title,
  //   };
  //   //if (shareUrl) share.url = shareUrl;

  //   share.url = postData?.permalink;

  //   //console.log("share: ", share);
  //   setShareData(share);
  //   if (!navigator || !navigator.share) {
  //     setOpenShareModal(true);
  //     console.log("Platform not supported!");
  //   } else {
  //     uiHelper.share(share);
  //   }
  // };

  // const handleBookmark = (_id, type) => {
  //   if (userLocalDetail?.id || session?.user?.email) {
  //     const postObj = {
  //       table: "post",
  //       postId: _id,
  //       type: type,
  //     };
  //     dispatch(saveBookmark(postObj));
  //     //setShowDropdown((e) => !e);
  //   } else {
  //     if (setLoginPrompt) setLoginPrompt(true);
  //   }
  // };

  return (
    <div className="headline-box1">
      <div className="headline-left-col" style={{ position: "relative" }}>
        <div className="headline-pic-box">
          {postData?.type === "liveblog" &&
            postData?.liveBlogData?.eventStatus === "live" && (
              <img
                src="/assets/images/giphy.gif"
                style={{
                  position: "absolute",
                  width: "80px",
                  zIndex: "99",
                  height: "80px",
                  right: "0",
                  top: "-10px",
                }}
                alt="live"
              ></img>
            )}
          <a
            href={`${
                postData?.eventStatus === undefined
                  ? pageToOpen
                  : `/business/post/${postData?.sefUrl}`
              // query: {
              //   categoryName: postStateData?.name,
              //   postSlug: postStateData?.postSlug,
              //    categorySlug: postStateData?.categorySlug,
              // },
            }`}
            passHref
          >
            <MyLink
              className="headline-thumb"
              onCustomClick={() => {
                console.log(isHomePath, "home path");
                if (isHomePath) {
                  pushGoogleTabEvent(
                    "lt_cards",
                    createHomeArticlePayload(postData, { Section: "Home" })
                  );
                  // ClevertapReact.event(
                  //   "homepage_card",
                  //   createHomeCardPayload("homepage_card", postStateData)
                  // );
                } else if (router.asPath === "/video") {
                  console.log("bdeio");
                  pushGoogleTabEvent(
                    "lt_cards",
                    createHomeArticlePayload(postData, {
                      Section: "Video",
                    })
                  );
                }
              }}
            >
              {/* <img
                src={postStateData?.headingImageUrl}
                alt={postStateData?.imgAltText || "News pic"}
              /> */}
              {postData?.headingImageUrl && (
                <img
                  src={
                    firstScroll
                      ? postData?.headingImageUrl + "?width=600"
                      : postData?.headingImageUrl + "?width=150"
                  }
                  // layout="fill"
                  //     sizes="(max-width: 1500px) 100vw,
                  //  (max-width: 768px) 70vw,
                  //  (max-width: 500px) 40vw
                  // "
                  alt={postData?.imgAltText || "News pic"}
                />
              )}
              {postData?.thumbnail && (
                <img
                  src={
                    firstScroll
                      ? postData?.thumbnail + "?width=600"
                      : postData?.thumbnail + "?width=150"
                  }
                  // layout="fill"
                  //     sizes="(max-width: 1500px) 100vw,
                  //  (max-width: 768px) 70vw,
                  //  (max-width: 500px) 40vw
                  // "
                  alt={postData?.imgAltText || "News pic"}
                />
              )}
              {
                <div className="news-type-box">
                  {!postData?.eventStatus && (
                    <i
                      className={`icon-${
                        postData?.type === "text" ||
                        postData?.type === "liveblog"
                          ? "cat-read"
                          : postData?.type === "audio"
                          ? "mic"
                          : "play-circle"
                      }`}
                    ></i>
                  )}
                  {postData?.type === "audio" && <span>{videoDuration}</span>}
                </div>
              }
            </MyLink>
          </a>
          {/* <div className="news-type-box">
            <i
              className={`icon-${
                postStateData?.type === "text"
                  ? "cat-read"
                  : postStateData?.type === "audio"
                  ? "mic"
                  : "play-circle"
              }`}
            ></i>
            {postStateData?.type === "audio" && <span>{videoDuration}</span>}
          </div> */}
        </div>
        <div className="headline-right-col" style={{ position: "relative" }}>
          <div className="headline-body">
            <div
              className="headline-info"
              style={{ paddingTop: "0.5rem", height: "6rem" }}
            >
              {hTag === 1 ? (
                <h1 style={{ marginBottom: "0rem", cursor: "pointer" }}>
                  <a
                    rel="preload"
                    href={
                        postData?.eventStatus === undefined
                          ? pageToOpen
                          : `/${postData?.category?.sefUrl}/post/${postData?.sefUrl}`
                      // query: {
                      //   categoryName: postStateData?.name,
                      //   postSlug: postStateData?.postSlug,
                      // },
                    }
                    passHref
                  >
                    {/* <div> */}
                    <a
                      rel="preload"
                      style={{
                        fontFamily:
                          "Mukta,system-ui, -apple-system, BlinkMacSystemFont",
                        fontStyle: "normal",
                        fontWeight: "550",
                        fontSize: "18px",
                        lineHeight: "135%",
                        color: "#000000",
                      }}
                      // onCustomClick={() => {
                      //   if (isHomePath) {
                      //     // ClevertapReact.event(
                      //     //   "homepage_card",
                      //     //   createHomeCardPayload(
                      //     //     "homepage_card",
                      //     //     postStateData
                      //     //   )
                      //     // );
                      //   }
                      // }}
                    >
                      {postData?.title?.padEnd(88)}
                    </a>
                    {/* </div> */}
                  </a>
                </h1>
              ) : hTag === 2 ? (
                <h2 style={{ marginBottom: "0rem", cursor: "pointer" }}>
                  <a
                    href={`${
                        postData?.eventStatus === undefined
                          ? pageToOpen
                          : `/${postData?.category?.sefUrl}/post/${postData?.sefUrl}`
                      // query: {
                      //   categoryName: postStateData?.name,
                      //   postSlug: postStateData?.postSlug,
                      // },
                    }`}
                    passHref
                  >
                    <div>
                      <MyLink
                        style={{
                          fontFamily:
                            "Mukta,system-ui, -apple-system, BlinkMacSystemFont",
                          fontStyle: "normal",
                          fontWeight: "550",
                          fontSize: "18px",
                          lineHeight: "135%",
                          color: "#000000",
                        }}
                        onCustomClick={() => {
                          if (isHomePath) {
                            // ClevertapReact.event(
                            //   "homepage_card",
                            //   createHomeCardPayload(
                            //     "homepage_card",
                            //     postStateData
                            //   )
                            // );
                          }
                        }}
                      >
                        {postData?.title?.padEnd(88)}
                      </MyLink>
                    </div>
                  </a>
                </h2>
              ) : (
                <h3 style={{ marginBottom: "0rem", cursor: "pointer" }}>
                  <a
                    href={`${
                        postData?.eventStatus === undefined
                          ? pageToOpen
                          : `/${postData?.category?.sefUrl}/post/${postData?.sefUrl}`
                      // query: {
                      //   categoryName: postStateData?.name,
                      //   postSlug: postStateData?.postSlug,
                      // },
                    }`}
                    passHref
                  >
                    <a
                      style={{
                        fontFamily:
                          "Mukta,system-ui, -apple-system, BlinkMacSystemFont",
                        fontStyle: "normal",
                        fontWeight: "550",
                        fontSize: "18px",
                        lineHeight: "135%",
                        color: "#000000",
                      }}
                      // onCustomClick={() => {
                      //   if (isHomePath) {
                      //     // ClevertapReact.event(
                      //     //   "homepage_card",
                      //     //   createHomeCardPayload(
                      //     //     "homepage_card",
                      //     //     postStateData
                      //     //   )
                      //     // );
                      //   }
                      // }}
                    >
                      {postData?.title?.padEnd(88)}
                    </a>
                  </a>
                </h3>
              )}
              {/* <p>{postStateData?.shortText?.substring(0, 75).concat("...")}</p> */}
            </div>
            {/* <div className="published-date">
              <span
                className="pre-span"
                style={{
                  fontFamily: "Hind",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "26px",
                  color: "#999999",
                }}
              >
                {postData?.publishedAt
                  ? timeSince(postData?.publishedAt)
                  : timeSince(postData?.createdAt)}{" "}
                ago
              </span>
            </div> */}
            <div
              className="line-newsImageTextBanner1"
              style={{
                height: "1px",
                background: "rgba(166, 166, 166, 0.2)",
                width: "100%",
              }}
            ></div>
            <div></div>
            {/* <div className="more-col">
            <OptionDropdown
              postData={postStateData}
              showLoginPrompt={() => setLoginPrompt(true)}
              shareUrl={shareUrl}
            />
          </div> */}
          </div>
          {/* <div
            className="headline-footer-section"
            style={{ marginTop: "30px" }}
          >
            <div className="published-date">
              <span className="pre-span">
                {postData?.publishedAt
                  ? timeSince(postData?.publishedAt)
                  : timeSince(postData?.createdAt)}{" "}
                ago
              </span>
            </div>
          </div> */}

          {/* <div className="three-dot-headline-menu">
            <img
              className="arrow-down dots"
              src="/assets/images/moreMenuVertical.png"
              width="20"
              height="20"
              alt=""
            />
          </div> */}
        </div>
      </div>

      <CustomPopup
        showModal={loginPrompt}
        onClose={() => setLoginPrompt(false)}
      />
      <Modal
        show={openShareModal}
        fullscreen={false}
        centered
        size="sm"
        onHide={() => setOpenShareModal(false)}
      >
        <div className="row p-2">
          <div className="col-6">Social Share</div>
          <div className="col-6 text-end">
            <div role={"button"} onClick={() => setOpenShareModal(false)}>
              <span className="icon-close"></span>
            </div>
          </div>
        </div>

        <Modal.Body>
          <div align="center">
            <FacebookShareButton quote={shareData.text} url={shareData.url}>
              <FacebookIcon className="me-1" size={32} round />
            </FacebookShareButton>

            <TwitterShareButton title={shareData.text} url={shareData.url}>
              <TwitterIcon className="me-1" size={32} round />
            </TwitterShareButton>

            <EmailShareButton
              url={shareData.url}
              subject={shareData.text}
              body={shareData.url}
            >
              <EmailIcon className="me-1" size={32} round />
            </EmailShareButton>

            <LinkedinShareButton title={shareData.text} url={shareData.url}>
              <LinkedinIcon className="me-1" size={32} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={shareData.url} title={shareData.text}>
              <WhatsappIcon className="me-1" size={32} round />
            </WhatsappShareButton>
            <hr />
            <span style={{ fontSize: "13px" }} className="text-dark">
              <span id="cpy">Copy</span> to Clipboard{" "}
              <i className="las la-clipboard la-lg"></i>
            </span>
            <input
              style={{ fontSize: "13px" }}
              onClick={() => {
                let et = document.getElementById("copy");
                et.select();
                document.execCommand("copy");
                document.getElementById("cpy").textContent = "Copied";
              }}
              id="copy"
              value={shareData.url}
              type="text"
              readOnly
              className="form-control"
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default NewsImageTextBanner1;
